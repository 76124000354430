
export default {
  data() {
    return {
      value: '1',
      zhif_rule: ''
    };
  },
  components: {
    
  },
  created() {},
  methods: {
    
  },
  mounted() {
    this.zhif_rule = window.appConfig.zhif_rule;
    if (this.zhif_rule === '002') {
      this.value = '2';
    } else {
      this.value = '1';
    }
  },
  activated() {
    
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
};
